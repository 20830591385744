/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActivityMetaTypeEnum = {
    Solo: 'solo',
    Dual: 'dual',
    Offspring: 'offspring',
    DualAndOffspring: 'dual_and_offspring'
} as const;
export type ActivityMetaTypeEnum = typeof ActivityMetaTypeEnum[keyof typeof ActivityMetaTypeEnum];


export function ActivityMetaTypeEnumFromJSON(json: any): ActivityMetaTypeEnum {
    return ActivityMetaTypeEnumFromJSONTyped(json, false);
}

export function ActivityMetaTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityMetaTypeEnum {
    return json as ActivityMetaTypeEnum;
}

export function ActivityMetaTypeEnumToJSON(value?: ActivityMetaTypeEnum | null): any {
    return value as any;
}

