/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BreedingPairingDetails,
  BreedingPairingDetailsFromJSON,
  BreedingPairingDetailsToJSON,
  BreedingPairingDetailsRequest,
  BreedingPairingDetailsRequestFromJSON,
  BreedingPairingDetailsRequestToJSON,
  BreedingPairingListByPrimaryAnimal,
  BreedingPairingListByPrimaryAnimalFromJSON,
  BreedingPairingListByPrimaryAnimalToJSON,
  OffspringGroup,
  OffspringGroupFromJSON,
  OffspringGroupToJSON,
  PaginatedBreedingPairingListList,
  PaginatedBreedingPairingListListFromJSON,
  PaginatedBreedingPairingListListToJSON,
  PaginatedOffspringGroupListList,
  PaginatedOffspringGroupListListFromJSON,
  PaginatedOffspringGroupListListToJSON,
  PaginatedPublicAnimalList,
  PaginatedPublicAnimalListFromJSON,
  PaginatedPublicAnimalListToJSON,
  PaginatedStoreSearchList,
  PaginatedStoreSearchListFromJSON,
  PaginatedStoreSearchListToJSON,
  PatchedBreedingPairingDetailsRequest,
  PatchedBreedingPairingDetailsRequestFromJSON,
  PatchedBreedingPairingDetailsRequestToJSON,
  Store,
  StoreFromJSON,
  StoreToJSON,
} from "../models";

export interface StoresBreedingPairingByRetrieveRequest {
  sex: StoresBreedingPairingByRetrieveSexEnum;
  storeId: string;
  ordering?: StoresBreedingPairingByRetrieveOrderingEnum;
  page?: number;
  pageSize?: number;
  search?: string;
}

export interface StoresBreedingPairingCreateRequest {
  storeId: string;
  /** @deprecated Use request instead. **/
  breedingPairingDetailsRequest?: BreedingPairingDetailsRequest;
  request: BreedingPairingDetailsRequest;
}

export interface StoresBreedingPairingCreateOffspringGroupCreateRequest {
  id: number;
  storeId: string;
  /** @deprecated Use request instead. **/
  breedingPairingDetailsRequest?: BreedingPairingDetailsRequest;
  request: BreedingPairingDetailsRequest;
}

export interface StoresBreedingPairingDestroyRequest {
  id: number;
  storeId: string;
}

export interface StoresBreedingPairingListRequest {
  by: StoresBreedingPairingListByEnum;
  storeId: string;
  categoryShortName?: string;
  ordering?: StoresBreedingPairingListOrderingEnum;
  page?: number;
  pageSize?: number;
  search?: string;
  season?: string;
  status?: StoresBreedingPairingListStatusEnum;
}

export interface StoresBreedingPairingOutcomesRetrieveRequest {
  storeId: string;
}

export interface StoresBreedingPairingPartialUpdateRequest {
  id: number;
  storeId: string;
  /** @deprecated Use request instead. **/
  patchedBreedingPairingDetailsRequest?: PatchedBreedingPairingDetailsRequest;
  request?: PatchedBreedingPairingDetailsRequest;
}

export interface StoresBreedingPairingRetrieveRequest {
  id: number;
  storeId: string;
}

export interface StoresBreedingPairingUpdateRequest {
  id: number;
  storeId: string;
  /** @deprecated Use request instead. **/
  breedingPairingDetailsRequest?: BreedingPairingDetailsRequest;
  request: BreedingPairingDetailsRequest;
}

export interface StoresCollectionListRequest {
  storeId: string;
  active?: StoresCollectionListActiveEnum;
  animalId?: string;
  animalIdIn?: Array<string>;
  breedingPairingPrimaryAnimalPk?: string;
  breedingTagSeason?: string;
  category?: string;
  clutchId?: string;
  clutchNumber?: Array<string>;
  damId?: Array<string>;
  isGravid?: string;
  maturity?: StoresCollectionListMaturityEnum;
  ordering?: StoresCollectionListOrderingEnum;
  page?: number;
  pageSize?: number;
  pairedWith?: number;
  petOnly?: string;
  preyFood?: StoresCollectionListPreyFoodEnum;
  preyStatus?: StoresCollectionListPreyStatusEnum;
  priceMax?: string;
  priceMin?: string;
  priceCurrency?: StoresCollectionListPriceCurrencyEnum;
  search?: Array<string>;
  sex?: Array<StoresCollectionListSexEnum>;
  sireId?: Array<string>;
  state?: Array<StoresCollectionListStateEnum>;
  status?: StoresCollectionListStatusEnum;
  tagsIn?: number;
  tagsNotIn?: number;
  traitCountMax?: number;
  traitCountMin?: number;
  traitsIn?: Array<string>;
  traitsNotIn?: Array<string>;
  transferred?: StoresCollectionListTransferredEnum;
  visibility?: StoresCollectionListVisibilityEnum;
  weightMax?: number | null;
  weightMin?: number | null;
}

export interface StoresFeaturedStoresListRequest {
  canPickup?: string;
  canShip?: string;
  category?: string;
  exportingTo?: string;
  followedStoresOnly?: string;
  hasWaitlists?: string;
  joinedSince?: Date;
  locationState?: string;
  minPositiveRatingPct?: number;
  minSellerRatingScore?: number;
  minimumListings?: number;
  ordering?: StoresFeaturedStoresListOrderingEnum;
  organizationType?: StoresFeaturedStoresListOrganizationTypeEnum;
  page?: number;
  pageSize?: number;
  paymentMethods?: Array<StoresFeaturedStoresListPaymentMethodsEnum>;
  paymentPlan?: number;
  preyFood?: StoresFeaturedStoresListPreyFoodEnum;
  preyStatus?: StoresFeaturedStoresListPreyStatusEnum;
  randomFeaturedStoresOnly?: string;
  region?: StoresFeaturedStoresListRegionEnum;
  search?: string;
  usarkMembership?: string;
  willTrade?: string;
}

export interface StoresListRequest {
  canPickup?: string;
  canShip?: string;
  category?: string;
  exportingTo?: string;
  followedStoresOnly?: string;
  hasWaitlists?: string;
  joinedSince?: Date;
  locationState?: string;
  minPositiveRatingPct?: number;
  minSellerRatingScore?: number;
  minimumListings?: number;
  ordering?: StoresListOrderingEnum;
  organizationType?: StoresListOrganizationTypeEnum;
  page?: number;
  pageSize?: number;
  paymentMethods?: Array<StoresListPaymentMethodsEnum>;
  paymentPlan?: number;
  preyFood?: StoresListPreyFoodEnum;
  preyStatus?: StoresListPreyStatusEnum;
  randomFeaturedStoresOnly?: string;
  region?: StoresListRegionEnum;
  search?: string;
  usarkMembership?: string;
  willTrade?: string;
}

export interface StoresOffspringGroupsListRequest {
  storeId: string;
  birthDate?: StoresOffspringGroupsListBirthDateEnum;
  category?: string;
  child?: string;
  clutchEggCount?: string;
  clutchEggCountBad?: string;
  clutchEggCountGood?: string;
  clutchEstimatedHatchDate?: StoresOffspringGroupsListClutchEstimatedHatchDateEnum;
  dam?: string;
  damId?: string;
  groupId?: string;
  offspringGroupId?: string;
  ordering?: StoresOffspringGroupsListOrderingEnum;
  otherAnimalId?: string;
  page?: number;
  pageSize?: number;
  parent?: string;
  pk?: string;
  primaryAnimalId?: string;
  search?: string;
  season?: string;
  sire?: string;
  sireId?: string;
  status?: StoresOffspringGroupsListStatusEnum;
  traitsIn?: string;
  traitsNotIn?: string;
  visibility?: StoresOffspringGroupsListVisibilityEnum;
}

export interface StoresOffspringGroupsRetrieveRequest {
  id: number;
  storeId: string;
}

export interface StoresRetrieveRequest {
  id: string;
}

/**
 *
 */
export class StoresApi extends runtime.BaseAPI {
  /**
   * This list view is used for mobile view, where it is grouped by primary animal, i.e. if one animal is in two pairings this view will return one records with pairings list for each record. It\'s different from desktop view, where each pairing has one record and is not grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/by/{female|male}/?...
   */
  async storesBreedingPairingByRetrieveRaw(
    requestParameters: StoresBreedingPairingByRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingListByPrimaryAnimal>> {
    if (requestParameters.sex === null || requestParameters.sex === undefined) {
      throw new runtime.RequiredError("sex", "Required parameter requestParameters.sex was null or undefined when calling storesBreedingPairingByRetrieve.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingByRetrieve.");
    }

    const queryParameters: any = {};

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/by/{sex}/`
          .replace(`{${"sex"}}`, encodeURIComponent(String(requestParameters.sex)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingListByPrimaryAnimalFromJSON(jsonValue));
  }

  /**
   * This list view is used for mobile view, where it is grouped by primary animal, i.e. if one animal is in two pairings this view will return one records with pairings list for each record. It\'s different from desktop view, where each pairing has one record and is not grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/by/{female|male}/?...
   */
  async storesBreedingPairingByRetrieve(
    requestParameters: StoresBreedingPairingByRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingListByPrimaryAnimal> {
    const response = await this.storesBreedingPairingByRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingCreateRaw(
    requestParameters: StoresBreedingPairingCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingCreate.");
    }

    if (
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === null ||
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "breedingPairingDetailsRequest",
        "Required parameter (requestParameters.request || requestParameters.breedingPairingDetailsRequest) was null or undefined when calling storesBreedingPairingCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/`.replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.breedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async storesBreedingPairingCreate(
    requestParameters: StoresBreedingPairingCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.storesBreedingPairingCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingCreateOffspringGroupCreateRaw(
    requestParameters: StoresBreedingPairingCreateOffspringGroupCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesBreedingPairingCreateOffspringGroupCreate.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError(
        "storeId",
        "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingCreateOffspringGroupCreate."
      );
    }

    if (
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === null ||
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "breedingPairingDetailsRequest",
        "Required parameter (requestParameters.request || requestParameters.breedingPairingDetailsRequest) was null or undefined when calling storesBreedingPairingCreateOffspringGroupCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/{id}/create_offspring_group/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.breedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async storesBreedingPairingCreateOffspringGroupCreate(
    requestParameters: StoresBreedingPairingCreateOffspringGroupCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.storesBreedingPairingCreateOffspringGroupCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingDestroyRaw(
    requestParameters: StoresBreedingPairingDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesBreedingPairingDestroy.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingDestroy.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async storesBreedingPairingDestroy(requestParameters: StoresBreedingPairingDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
    await this.storesBreedingPairingDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * This list view is used for desktop view, where it is not grouped by primary animal, i.e. if one animal is in two pairings this view will return two records. It\'s different for mobile views, where it is grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/?by={female|male}&...
   */
  async storesBreedingPairingListRaw(
    requestParameters: StoresBreedingPairingListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedBreedingPairingListList>> {
    if (requestParameters.by === null || requestParameters.by === undefined) {
      throw new runtime.RequiredError("by", "Required parameter requestParameters.by was null or undefined when calling storesBreedingPairingList.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingList.");
    }

    const queryParameters: any = {};

    if (requestParameters.by !== undefined) {
      queryParameters["by"] = requestParameters.by;
    }

    if (requestParameters.categoryShortName !== undefined) {
      queryParameters["category_short_name"] = requestParameters.categoryShortName;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.season !== undefined) {
      queryParameters["season"] = requestParameters.season;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/`.replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedBreedingPairingListListFromJSON(jsonValue));
  }

  /**
   * This list view is used for desktop view, where it is not grouped by primary animal, i.e. if one animal is in two pairings this view will return two records. It\'s different for mobile views, where it is grouped by primary animal. url for this view is like this: `api/v1/breeding_pairing/?by={female|male}&...
   */
  async storesBreedingPairingList(
    requestParameters: StoresBreedingPairingListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedBreedingPairingListList> {
    const response = await this.storesBreedingPairingListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingOutcomesRetrieveRaw(
    requestParameters: StoresBreedingPairingOutcomesRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingOutcomesRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/outcomes/`.replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async storesBreedingPairingOutcomesRetrieve(
    requestParameters: StoresBreedingPairingOutcomesRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.storesBreedingPairingOutcomesRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingPartialUpdateRaw(
    requestParameters: StoresBreedingPairingPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesBreedingPairingPartialUpdate.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingPartialUpdate.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchedBreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.patchedBreedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async storesBreedingPairingPartialUpdate(
    requestParameters: StoresBreedingPairingPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.storesBreedingPairingPartialUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingRetrieveRaw(
    requestParameters: StoresBreedingPairingRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesBreedingPairingRetrieve.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async storesBreedingPairingRetrieve(
    requestParameters: StoresBreedingPairingRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.storesBreedingPairingRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesBreedingPairingUpdateRaw(
    requestParameters: StoresBreedingPairingUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BreedingPairingDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesBreedingPairingUpdate.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesBreedingPairingUpdate.");
    }

    if (
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === null ||
      (requestParameters.request || requestParameters.breedingPairingDetailsRequest) === undefined
    ) {
      throw new runtime.RequiredError(
        "breedingPairingDetailsRequest",
        "Required parameter (requestParameters.request || requestParameters.breedingPairingDetailsRequest) was null or undefined when calling storesBreedingPairingUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/breeding_pairing/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: BreedingPairingDetailsRequestToJSON(requestParameters.request || requestParameters.breedingPairingDetailsRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BreedingPairingDetailsFromJSON(jsonValue));
  }

  /**
   */
  async storesBreedingPairingUpdate(
    requestParameters: StoresBreedingPairingUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BreedingPairingDetails> {
    const response = await this.storesBreedingPairingUpdateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesCollectionListRaw(
    requestParameters: StoresCollectionListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedPublicAnimalList>> {
    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesCollectionList.");
    }

    const queryParameters: any = {};

    if (requestParameters.active !== undefined) {
      queryParameters["active"] = requestParameters.active;
    }

    if (requestParameters.animalId !== undefined) {
      queryParameters["animal_id"] = requestParameters.animalId;
    }

    if (requestParameters.animalIdIn) {
      queryParameters["animal_id_in"] = requestParameters.animalIdIn;
    }

    if (requestParameters.breedingPairingPrimaryAnimalPk !== undefined) {
      queryParameters["breeding_pairing_primary_animal_pk"] = requestParameters.breedingPairingPrimaryAnimalPk;
    }

    if (requestParameters.breedingTagSeason !== undefined) {
      queryParameters["breeding_tag_season"] = requestParameters.breedingTagSeason;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.clutchId !== undefined) {
      queryParameters["clutch_id"] = requestParameters.clutchId;
    }

    if (requestParameters.clutchNumber) {
      queryParameters["clutch_number"] = requestParameters.clutchNumber;
    }

    if (requestParameters.damId) {
      queryParameters["dam_id"] = requestParameters.damId;
    }

    if (requestParameters.isGravid !== undefined) {
      queryParameters["is_gravid"] = requestParameters.isGravid;
    }

    if (requestParameters.maturity !== undefined) {
      queryParameters["maturity"] = requestParameters.maturity;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.pairedWith !== undefined) {
      queryParameters["paired_with"] = requestParameters.pairedWith;
    }

    if (requestParameters.petOnly !== undefined) {
      queryParameters["pet_only"] = requestParameters.petOnly;
    }

    if (requestParameters.preyFood !== undefined) {
      queryParameters["prey_food"] = requestParameters.preyFood;
    }

    if (requestParameters.preyStatus !== undefined) {
      queryParameters["prey_status"] = requestParameters.preyStatus;
    }

    if (requestParameters.priceMax !== undefined) {
      queryParameters["price__max"] = requestParameters.priceMax;
    }

    if (requestParameters.priceMin !== undefined) {
      queryParameters["price__min"] = requestParameters.priceMin;
    }

    if (requestParameters.priceCurrency !== undefined) {
      queryParameters["price_currency"] = requestParameters.priceCurrency;
    }

    if (requestParameters.search) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.sex) {
      queryParameters["sex"] = requestParameters.sex;
    }

    if (requestParameters.sireId) {
      queryParameters["sire_id"] = requestParameters.sireId;
    }

    if (requestParameters.state) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.tagsIn !== undefined) {
      queryParameters["tags__in"] = requestParameters.tagsIn;
    }

    if (requestParameters.tagsNotIn !== undefined) {
      queryParameters["tags__not__in"] = requestParameters.tagsNotIn;
    }

    if (requestParameters.traitCountMax !== undefined) {
      queryParameters["trait_count__max"] = requestParameters.traitCountMax;
    }

    if (requestParameters.traitCountMin !== undefined) {
      queryParameters["trait_count__min"] = requestParameters.traitCountMin;
    }

    if (requestParameters.traitsIn) {
      queryParameters["traits__in"] = requestParameters.traitsIn;
    }

    if (requestParameters.traitsNotIn) {
      queryParameters["traits__not__in"] = requestParameters.traitsNotIn;
    }

    if (requestParameters.transferred !== undefined) {
      queryParameters["transferred"] = requestParameters.transferred;
    }

    if (requestParameters.visibility !== undefined) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    if (requestParameters.weightMax !== undefined) {
      queryParameters["weight__max"] = requestParameters.weightMax;
    }

    if (requestParameters.weightMin !== undefined) {
      queryParameters["weight__min"] = requestParameters.weightMin;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/collection/`.replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicAnimalListFromJSON(jsonValue));
  }

  /**
   */
  async storesCollectionList(requestParameters: StoresCollectionListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaginatedPublicAnimalList> {
    const response = await this.storesCollectionListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesFeaturedStoresListRaw(
    requestParameters: StoresFeaturedStoresListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedStoreSearchList>> {
    const queryParameters: any = {};

    if (requestParameters.canPickup !== undefined) {
      queryParameters["can_pickup"] = requestParameters.canPickup;
    }

    if (requestParameters.canShip !== undefined) {
      queryParameters["can_ship"] = requestParameters.canShip;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.exportingTo !== undefined) {
      queryParameters["exporting_to"] = requestParameters.exportingTo;
    }

    if (requestParameters.followedStoresOnly !== undefined) {
      queryParameters["followed_stores_only"] = requestParameters.followedStoresOnly;
    }

    if (requestParameters.hasWaitlists !== undefined) {
      queryParameters["has_waitlists"] = requestParameters.hasWaitlists;
    }

    if (requestParameters.joinedSince !== undefined) {
      queryParameters["joined_since"] = (requestParameters.joinedSince as any).toISOString();
    }

    if (requestParameters.locationState !== undefined) {
      queryParameters["location_state"] = requestParameters.locationState;
    }

    if (requestParameters.minPositiveRatingPct !== undefined) {
      queryParameters["min_positive_rating_pct"] = requestParameters.minPositiveRatingPct;
    }

    if (requestParameters.minSellerRatingScore !== undefined) {
      queryParameters["min_seller_rating_score"] = requestParameters.minSellerRatingScore;
    }

    if (requestParameters.minimumListings !== undefined) {
      queryParameters["minimum_listings"] = requestParameters.minimumListings;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.organizationType !== undefined) {
      queryParameters["organization_type"] = requestParameters.organizationType;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.paymentMethods) {
      queryParameters["payment_methods"] = requestParameters.paymentMethods;
    }

    if (requestParameters.paymentPlan !== undefined) {
      queryParameters["payment_plan"] = requestParameters.paymentPlan;
    }

    if (requestParameters.preyFood !== undefined) {
      queryParameters["prey_food"] = requestParameters.preyFood;
    }

    if (requestParameters.preyStatus !== undefined) {
      queryParameters["prey_status"] = requestParameters.preyStatus;
    }

    if (requestParameters.randomFeaturedStoresOnly !== undefined) {
      queryParameters["random_featured_stores_only"] = requestParameters.randomFeaturedStoresOnly;
    }

    if (requestParameters.region !== undefined) {
      queryParameters["region"] = requestParameters.region;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.usarkMembership !== undefined) {
      queryParameters["usark_membership"] = requestParameters.usarkMembership;
    }

    if (requestParameters.willTrade !== undefined) {
      queryParameters["will_trade"] = requestParameters.willTrade;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/featured_stores/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedStoreSearchListFromJSON(jsonValue));
  }

  /**
   */
  async storesFeaturedStoresList(
    requestParameters: StoresFeaturedStoresListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedStoreSearchList> {
    const response = await this.storesFeaturedStoresListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Overrides default list implementation to pass down filtering and ordering data to self.get_paginated_response.
   */
  async storesListRaw(requestParameters: StoresListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaginatedStoreSearchList>> {
    const queryParameters: any = {};

    if (requestParameters.canPickup !== undefined) {
      queryParameters["can_pickup"] = requestParameters.canPickup;
    }

    if (requestParameters.canShip !== undefined) {
      queryParameters["can_ship"] = requestParameters.canShip;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.exportingTo !== undefined) {
      queryParameters["exporting_to"] = requestParameters.exportingTo;
    }

    if (requestParameters.followedStoresOnly !== undefined) {
      queryParameters["followed_stores_only"] = requestParameters.followedStoresOnly;
    }

    if (requestParameters.hasWaitlists !== undefined) {
      queryParameters["has_waitlists"] = requestParameters.hasWaitlists;
    }

    if (requestParameters.joinedSince !== undefined) {
      queryParameters["joined_since"] = (requestParameters.joinedSince as any).toISOString();
    }

    if (requestParameters.locationState !== undefined) {
      queryParameters["location_state"] = requestParameters.locationState;
    }

    if (requestParameters.minPositiveRatingPct !== undefined) {
      queryParameters["min_positive_rating_pct"] = requestParameters.minPositiveRatingPct;
    }

    if (requestParameters.minSellerRatingScore !== undefined) {
      queryParameters["min_seller_rating_score"] = requestParameters.minSellerRatingScore;
    }

    if (requestParameters.minimumListings !== undefined) {
      queryParameters["minimum_listings"] = requestParameters.minimumListings;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.organizationType !== undefined) {
      queryParameters["organization_type"] = requestParameters.organizationType;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.paymentMethods) {
      queryParameters["payment_methods"] = requestParameters.paymentMethods;
    }

    if (requestParameters.paymentPlan !== undefined) {
      queryParameters["payment_plan"] = requestParameters.paymentPlan;
    }

    if (requestParameters.preyFood !== undefined) {
      queryParameters["prey_food"] = requestParameters.preyFood;
    }

    if (requestParameters.preyStatus !== undefined) {
      queryParameters["prey_status"] = requestParameters.preyStatus;
    }

    if (requestParameters.randomFeaturedStoresOnly !== undefined) {
      queryParameters["random_featured_stores_only"] = requestParameters.randomFeaturedStoresOnly;
    }

    if (requestParameters.region !== undefined) {
      queryParameters["region"] = requestParameters.region;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.usarkMembership !== undefined) {
      queryParameters["usark_membership"] = requestParameters.usarkMembership;
    }

    if (requestParameters.willTrade !== undefined) {
      queryParameters["will_trade"] = requestParameters.willTrade;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedStoreSearchListFromJSON(jsonValue));
  }

  /**
   * Overrides default list implementation to pass down filtering and ordering data to self.get_paginated_response.
   */
  async storesList(requestParameters: StoresListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaginatedStoreSearchList> {
    const response = await this.storesListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesOffspringGroupsListRaw(
    requestParameters: StoresOffspringGroupsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PaginatedOffspringGroupListList>> {
    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesOffspringGroupsList.");
    }

    const queryParameters: any = {};

    if (requestParameters.birthDate !== undefined) {
      queryParameters["birth_date"] = requestParameters.birthDate;
    }

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category;
    }

    if (requestParameters.child !== undefined) {
      queryParameters["child"] = requestParameters.child;
    }

    if (requestParameters.clutchEggCount !== undefined) {
      queryParameters["clutch_egg_count"] = requestParameters.clutchEggCount;
    }

    if (requestParameters.clutchEggCountBad !== undefined) {
      queryParameters["clutch_egg_count_bad"] = requestParameters.clutchEggCountBad;
    }

    if (requestParameters.clutchEggCountGood !== undefined) {
      queryParameters["clutch_egg_count_good"] = requestParameters.clutchEggCountGood;
    }

    if (requestParameters.clutchEstimatedHatchDate !== undefined) {
      queryParameters["clutch_estimated_hatch_date"] = requestParameters.clutchEstimatedHatchDate;
    }

    if (requestParameters.dam !== undefined) {
      queryParameters["dam"] = requestParameters.dam;
    }

    if (requestParameters.damId !== undefined) {
      queryParameters["dam_id"] = requestParameters.damId;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.offspringGroupId !== undefined) {
      queryParameters["offspring_group_id"] = requestParameters.offspringGroupId;
    }

    if (requestParameters.ordering !== undefined) {
      queryParameters["ordering"] = requestParameters.ordering;
    }

    if (requestParameters.otherAnimalId !== undefined) {
      queryParameters["other_animal_id"] = requestParameters.otherAnimalId;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.parent !== undefined) {
      queryParameters["parent"] = requestParameters.parent;
    }

    if (requestParameters.pk !== undefined) {
      queryParameters["pk"] = requestParameters.pk;
    }

    if (requestParameters.primaryAnimalId !== undefined) {
      queryParameters["primary_animal_id"] = requestParameters.primaryAnimalId;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.season !== undefined) {
      queryParameters["season"] = requestParameters.season;
    }

    if (requestParameters.sire !== undefined) {
      queryParameters["sire"] = requestParameters.sire;
    }

    if (requestParameters.sireId !== undefined) {
      queryParameters["sire_id"] = requestParameters.sireId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.traitsIn !== undefined) {
      queryParameters["traits__in"] = requestParameters.traitsIn;
    }

    if (requestParameters.traitsNotIn !== undefined) {
      queryParameters["traits__not__in"] = requestParameters.traitsNotIn;
    }

    if (requestParameters.visibility !== undefined) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/offspring_groups/`.replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOffspringGroupListListFromJSON(jsonValue));
  }

  /**
   */
  async storesOffspringGroupsList(
    requestParameters: StoresOffspringGroupsListRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PaginatedOffspringGroupListList> {
    const response = await this.storesOffspringGroupsListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesOffspringGroupsRetrieveRaw(
    requestParameters: StoresOffspringGroupsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<OffspringGroup>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesOffspringGroupsRetrieve.");
    }

    if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
      throw new runtime.RequiredError("storeId", "Required parameter requestParameters.storeId was null or undefined when calling storesOffspringGroupsRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{store_id}/offspring_groups/{id}/`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"store_id"}}`, encodeURIComponent(String(requestParameters.storeId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OffspringGroupFromJSON(jsonValue));
  }

  /**
   */
  async storesOffspringGroupsRetrieve(requestParameters: StoresOffspringGroupsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OffspringGroup> {
    const response = await this.storesOffspringGroupsRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async storesOwnRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Store>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/_own/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StoreFromJSON(jsonValue));
  }

  /**
   */
  async storesOwnRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Store> {
    const response = await this.storesOwnRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async storesRetrieveRaw(requestParameters: StoresRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Store>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling storesRetrieve.");
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
      headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/stores/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StoreFromJSON(jsonValue));
  }

  /**
   */
  async storesRetrieve(requestParameters: StoresRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Store> {
    const response = await this.storesRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const StoresBreedingPairingByRetrieveSexEnum = {
  Female: "female",
  Male: "male",
} as const;
export type StoresBreedingPairingByRetrieveSexEnum = (typeof StoresBreedingPairingByRetrieveSexEnum)[keyof typeof StoresBreedingPairingByRetrieveSexEnum];
/**
 * @export
 */
export const StoresBreedingPairingByRetrieveOrderingEnum = {
  AssignedCount: "assigned_count",
  Category: "category",
  OffspringGroup: "offspring_group",
  RecentPairingDate: "recent_pairing_date",
  Season: "season",
  Title: "title",
} as const;
export type StoresBreedingPairingByRetrieveOrderingEnum = (typeof StoresBreedingPairingByRetrieveOrderingEnum)[keyof typeof StoresBreedingPairingByRetrieveOrderingEnum];
/**
 * @export
 */
export const StoresBreedingPairingListByEnum = {
  Female: "female",
  Male: "male",
} as const;
export type StoresBreedingPairingListByEnum = (typeof StoresBreedingPairingListByEnum)[keyof typeof StoresBreedingPairingListByEnum];
/**
 * @export
 */
export const StoresBreedingPairingListOrderingEnum = {
  AssignedCount: "assigned_count",
  BreedingPairingVisibility: "breeding_pairing_visibility",
  Category: "category",
  HasImg: "has_img",
  OffspringGroup: "offspring_group",
  OlderPairingDate: "older_pairing_date",
  OtherAnimalHasImg: "other_animal_has_img",
  OtherAnimalTitle: "other_animal_title",
  RecentPairingDate: "recent_pairing_date",
  Season: "season",
  Title: "title",
} as const;
export type StoresBreedingPairingListOrderingEnum = (typeof StoresBreedingPairingListOrderingEnum)[keyof typeof StoresBreedingPairingListOrderingEnum];
/**
 * @export
 */
export const StoresBreedingPairingListStatusEnum = {
  ActiveCollection: "active_collection",
  All: "all",
  Archived: "archived",
  Breeder: "breeder",
  Collection: "collection",
  CurrentInventory: "current_inventory",
  Expired: "expired",
  ForSale: "for_sale",
  Holdback: "holdback",
  Inactive: "inactive",
  LiveListings: "live_listings",
  Loaned: "loaned",
  Nfs: "nfs",
  NotActiveCollection: "not_active_collection",
  NotLiveListings: "not_live_listings",
  OnHold: "on_hold",
  Pet: "pet",
  Purchased: "purchased",
  Sold: "sold",
} as const;
export type StoresBreedingPairingListStatusEnum = (typeof StoresBreedingPairingListStatusEnum)[keyof typeof StoresBreedingPairingListStatusEnum];
/**
 * @export
 */
export const StoresCollectionListActiveEnum = {
  Active: "active",
  Expired: "expired",
  Inactive: "inactive",
} as const;
export type StoresCollectionListActiveEnum = (typeof StoresCollectionListActiveEnum)[keyof typeof StoresCollectionListActiveEnum];
/**
 * @export
 */
export const StoresCollectionListMaturityEnum = {
  Adult: "adult",
  Baby: "baby",
  Juvenile: "juvenile",
  NotReady: "not_ready",
  Subadult: "subadult",
} as const;
export type StoresCollectionListMaturityEnum = (typeof StoresCollectionListMaturityEnum)[keyof typeof StoresCollectionListMaturityEnum];
/**
 * @export
 */
export const StoresCollectionListOrderingEnum = {
  Image: "image",
  Title: "title",
  BirthDate: "birth_date",
  Sex: "sex",
  Maturity: "maturity",
  Category: "category",
  Weight: "weight",
  Price: "price",
  WholesalePrice: "wholesale_price",
  LastUpdated: "last_updated",
  Traits: "traits",
  Quantity: "quantity",
  AnimalId: "animal_id",
  GroupId: "group_id",
  Status: "status",
  Visibility: "visibility",
  Active: "active",
  Clicks: "clicks",
  Impressions: "impressions",
  Inquiries: "inquiries",
  TitleId: "title_id",
  Dams: "dams",
  Sires: "sires",
  Tags: "tags",
  LastRenewal: "last_renewal",
  ImagesFirst: "images_first",
  AvailableForOrder: "available_for_order",
  AcceptOffers: "accept_offers",
  TransferredFrom: "transferred_from",
  Image: "-image",
  Title: "-title",
  BirthDate: "-birth_date",
  Sex: "-sex",
  Maturity: "-maturity",
  Category: "-category",
  Weight: "-weight",
  Price: "-price",
  WholesalePrice: "-wholesale_price",
  LastUpdated: "-last_updated",
  Traits: "-traits",
  Quantity: "-quantity",
  AnimalId: "-animal_id",
  GroupId: "-group_id",
  Status: "-status",
  Visibility: "-visibility",
  Active: "-active",
  Clicks: "-clicks",
  Impressions: "-impressions",
  Inquiries: "-inquiries",
  TitleId: "-title_id",
  Dams: "-dams",
  Sires: "-sires",
  Tags: "-tags",
  LastRenewal: "-last_renewal",
  ImagesFirst: "-images_first",
  AvailableForOrder: "-available_for_order",
  AcceptOffers: "-accept_offers",
  TransferredFrom: "-transferred_from",
} as const;
export type StoresCollectionListOrderingEnum = (typeof StoresCollectionListOrderingEnum)[keyof typeof StoresCollectionListOrderingEnum];
/**
 * @export
 */
export const StoresCollectionListPreyFoodEnum = {
  Chicken: "chicken",
  Cricket: "cricket",
  DryFeed: "dry-feed",
  Goat: "goat",
  GuineaPig: "guinea-pig",
  Lamb: "lamb",
  MealReplacement: "meal-replacement",
  Mealworm: "mealworm",
  Mouse: "mouse",
  Other: "other",
  Pig: "pig",
  Quail: "quail",
  Rabbit: "rabbit",
  Rat: "rat",
  Roach: "roach",
  SoftFurredRat: "soft-furred-rat",
  Unknown: "unknown",
  Vegetables: "vegetables",
} as const;
export type StoresCollectionListPreyFoodEnum = (typeof StoresCollectionListPreyFoodEnum)[keyof typeof StoresCollectionListPreyFoodEnum];
/**
 * @export
 */
export const StoresCollectionListPreyStatusEnum = {
  Frozen: "frozen",
  Live: "live",
  Prekilled: "prekilled",
  Unknown: "unknown",
} as const;
export type StoresCollectionListPreyStatusEnum = (typeof StoresCollectionListPreyStatusEnum)[keyof typeof StoresCollectionListPreyStatusEnum];
/**
 * @export
 */
export const StoresCollectionListPriceCurrencyEnum = {
  Aed: "AED",
  Afn: "AFN",
  All: "ALL",
  Amd: "AMD",
  Ang: "ANG",
  Aoa: "AOA",
  Ars: "ARS",
  Aud: "AUD",
  Awg: "AWG",
  Azn: "AZN",
  Bam: "BAM",
  Bbd: "BBD",
  Bdt: "BDT",
  Bgn: "BGN",
  Bhd: "BHD",
  Bif: "BIF",
  Bmd: "BMD",
  Bnd: "BND",
  Brl: "BRL",
  Bsd: "BSD",
  Btn: "BTN",
  Bwp: "BWP",
  Byr: "BYR",
  Bzd: "BZD",
  Cad: "CAD",
  Cdf: "CDF",
  Chf: "CHF",
  Clp: "CLP",
  Cny: "CNY",
  Cop: "COP",
  Crc: "CRC",
  Cuc: "CUC",
  Cup: "CUP",
  Cve: "CVE",
  Czk: "CZK",
  Djf: "DJF",
  Dkk: "DKK",
  Dop: "DOP",
  Dzd: "DZD",
  Egp: "EGP",
  Ern: "ERN",
  Etb: "ETB",
  Eur: "EUR",
  Fjd: "FJD",
  Fkp: "FKP",
  Gbp: "GBP",
  Gel: "GEL",
  Ghs: "GHS",
  Gip: "GIP",
  Gmd: "GMD",
  Gnf: "GNF",
  Gtq: "GTQ",
  Gyd: "GYD",
  Hkd: "HKD",
  Hnl: "HNL",
  Hrk: "HRK",
  Htg: "HTG",
  Huf: "HUF",
  Idr: "IDR",
  Ils: "ILS",
  Imp: "IMP",
  Inr: "INR",
  Iqd: "IQD",
  Irr: "IRR",
  Isk: "ISK",
  Jmd: "JMD",
  Jod: "JOD",
  Jpy: "JPY",
  Kes: "KES",
  Kgs: "KGS",
  Khr: "KHR",
  Kmf: "KMF",
  Kpw: "KPW",
  Krw: "KRW",
  Kwd: "KWD",
  Kyd: "KYD",
  Kzt: "KZT",
  Lak: "LAK",
  Lbp: "LBP",
  Lkr: "LKR",
  Lrd: "LRD",
  Lsl: "LSL",
  Ltl: "LTL",
  Lvl: "LVL",
  Lyd: "LYD",
  Mad: "MAD",
  Mdl: "MDL",
  Mga: "MGA",
  Mkd: "MKD",
  Mmk: "MMK",
  Mnt: "MNT",
  Mop: "MOP",
  Mro: "MRO",
  Mur: "MUR",
  Mvr: "MVR",
  Mwk: "MWK",
  Mxn: "MXN",
  Myr: "MYR",
  Mzn: "MZN",
  Nad: "NAD",
  Ngn: "NGN",
  Nio: "NIO",
  Nok: "NOK",
  Npr: "NPR",
  Nzd: "NZD",
  Omr: "OMR",
  Pen: "PEN",
  Pgk: "PGK",
  Php: "PHP",
  Pkr: "PKR",
  Pln: "PLN",
  Pyg: "PYG",
  Qar: "QAR",
  Ron: "RON",
  Rsd: "RSD",
  Rub: "RUB",
  Rwf: "RWF",
  Sar: "SAR",
  Sbd: "SBD",
  Scr: "SCR",
  Sdg: "SDG",
  Sek: "SEK",
  Sgd: "SGD",
  Shp: "SHP",
  Sll: "SLL",
  Sos: "SOS",
  Srd: "SRD",
  Std: "STD",
  Syp: "SYP",
  Szl: "SZL",
  Thb: "THB",
  Tjs: "TJS",
  Tnd: "TND",
  Top: "TOP",
  Try: "TRY",
  Ttd: "TTD",
  Twd: "TWD",
  Tzs: "TZS",
  Uah: "UAH",
  Ugx: "UGX",
  Usd: "USD",
  Uzs: "UZS",
  Vef: "VEF",
  Vnd: "VND",
  Vuv: "VUV",
  Wst: "WST",
  Xaf: "XAF",
  Xag: "XAG",
  Xcd: "XCD",
  Xdr: "XDR",
  Xof: "XOF",
  Xpd: "XPD",
  Xpf: "XPF",
  Xpt: "XPT",
  Yer: "YER",
  Zar: "ZAR",
  Zmk: "ZMK",
  Zmw: "ZMW",
  Zwl: "ZWL",
} as const;
export type StoresCollectionListPriceCurrencyEnum = (typeof StoresCollectionListPriceCurrencyEnum)[keyof typeof StoresCollectionListPriceCurrencyEnum];
/**
 * @export
 */
export const StoresCollectionListSexEnum = {
  Female: "female",
  Male: "male",
  Mixed: "mixed",
  Unknown: "unknown",
} as const;
export type StoresCollectionListSexEnum = (typeof StoresCollectionListSexEnum)[keyof typeof StoresCollectionListSexEnum];
/**
 * @export
 */
export const StoresCollectionListStateEnum = {
  Archived: "archived",
  Breeder: "breeder",
  ForSale: "for_sale",
  Holdback: "holdback",
  Loaned: "loaned",
  Nfs: "nfs",
  OnHold: "on_hold",
  OnHoldAuction: "on_hold_auction",
  Pet: "pet",
  Sold: "sold",
  SoldAuction: "sold_auction",
} as const;
export type StoresCollectionListStateEnum = (typeof StoresCollectionListStateEnum)[keyof typeof StoresCollectionListStateEnum];
/**
 * @export
 */
export const StoresCollectionListStatusEnum = {
  ActiveCollection: "active_collection",
  All: "all",
  Archived: "archived",
  Breeder: "breeder",
  Collection: "collection",
  CurrentInventory: "current_inventory",
  Expired: "expired",
  ForSale: "for_sale",
  Holdback: "holdback",
  Inactive: "inactive",
  LiveListings: "live_listings",
  Loaned: "loaned",
  NeedsUpdate: "needs_update",
  Nfs: "nfs",
  NotActiveCollection: "not_active_collection",
  NotLiveListings: "not_live_listings",
  OnHold: "on_hold",
  Pet: "pet",
  Purchased: "purchased",
  Sold: "sold",
} as const;
export type StoresCollectionListStatusEnum = (typeof StoresCollectionListStatusEnum)[keyof typeof StoresCollectionListStatusEnum];
/**
 * @export
 */
export const StoresCollectionListTransferredEnum = {
  NotTransferred: "not_transferred",
  Transferred: "transferred",
} as const;
export type StoresCollectionListTransferredEnum = (typeof StoresCollectionListTransferredEnum)[keyof typeof StoresCollectionListTransferredEnum];
/**
 * @export
 */
export const StoresCollectionListVisibilityEnum = {
  Private: "private",
  Public: "public",
  Unlisted: "unlisted",
} as const;
export type StoresCollectionListVisibilityEnum = (typeof StoresCollectionListVisibilityEnum)[keyof typeof StoresCollectionListVisibilityEnum];
/**
 * @export
 */
export const StoresFeaturedStoresListOrderingEnum = {
  Default: "default",
  StoreCaption: "store_caption",
  AdsCount: "ads_count",
  StoreCaption: "-store_caption",
  AdsCount: "-ads_count",
} as const;
export type StoresFeaturedStoresListOrderingEnum = (typeof StoresFeaturedStoresListOrderingEnum)[keyof typeof StoresFeaturedStoresListOrderingEnum];
/**
 * @export
 */
export const StoresFeaturedStoresListOrganizationTypeEnum = {
  Advocacy: "advocacy",
  Artist: "artist",
  Breeder: "breeder",
  Charity: "charity",
  Club: "club",
  ContentCreator: "content-creator",
  Courier: "courier",
  Education: "education",
  Expo: "expo",
  FeederBreeder: "feeder-breeder",
  HerpParty: "herp-party",
  ImporterExporter: "importer-exporter",
  Manufacturer: "manufacturer",
  Other: "other",
  PetStore: "pet-store",
  Publisher: "publisher",
  Rescue: "rescue",
  Retail: "retail",
  Seller: "seller",
  ShippingService: "shipping-service",
  Software: "software",
  TestingService: "testing-service",
  Vet: "vet",
  Zoo: "zoo",
} as const;
export type StoresFeaturedStoresListOrganizationTypeEnum = (typeof StoresFeaturedStoresListOrganizationTypeEnum)[keyof typeof StoresFeaturedStoresListOrganizationTypeEnum];
/**
 * @export
 */
export const StoresFeaturedStoresListPaymentMethodsEnum = {
  ApplePay: "apple_pay",
  Bitcoin: "bitcoin",
  Cash: "cash",
  Cashapp: "cashapp",
  CashiersCheck: "cashiers_check",
  Chime: "chime",
  CreditCard: "credit_card",
  DebitCard: "debit_card",
  Dogecoin: "dogecoin",
  Ethereum: "ethereum",
  GooglePay: "google_pay",
  MoneyOrder: "money_order",
  Moneygram: "moneygram",
  Other: "other",
  OtherCrypto: "other_crypto",
  PaypalFriendsFamily: "paypal_friends_family",
  PaypalGoodsServices: "paypal_goods_services",
  PersonalCheck: "personal_check",
  Venmo: "venmo",
  WalmartPay: "walmart_pay",
  WesternUnion: "western_union",
  WireTransfer: "wire_transfer",
  Zelle: "zelle",
} as const;
export type StoresFeaturedStoresListPaymentMethodsEnum = (typeof StoresFeaturedStoresListPaymentMethodsEnum)[keyof typeof StoresFeaturedStoresListPaymentMethodsEnum];
/**
 * @export
 */
export const StoresFeaturedStoresListPreyFoodEnum = {
  Chicken: "chicken",
  Cricket: "cricket",
  DryFeed: "dry-feed",
  Goat: "goat",
  GuineaPig: "guinea-pig",
  Lamb: "lamb",
  MealReplacement: "meal-replacement",
  Mealworm: "mealworm",
  Mouse: "mouse",
  Other: "other",
  Pig: "pig",
  Quail: "quail",
  Rabbit: "rabbit",
  Rat: "rat",
  Roach: "roach",
  SoftFurredRat: "soft-furred-rat",
  Unknown: "unknown",
  Vegetables: "vegetables",
} as const;
export type StoresFeaturedStoresListPreyFoodEnum = (typeof StoresFeaturedStoresListPreyFoodEnum)[keyof typeof StoresFeaturedStoresListPreyFoodEnum];
/**
 * @export
 */
export const StoresFeaturedStoresListPreyStatusEnum = {
  Frozen: "frozen",
  Live: "live",
  Prekilled: "prekilled",
  Unknown: "unknown",
} as const;
export type StoresFeaturedStoresListPreyStatusEnum = (typeof StoresFeaturedStoresListPreyStatusEnum)[keyof typeof StoresFeaturedStoresListPreyStatusEnum];
/**
 * @export
 */
export const StoresFeaturedStoresListRegionEnum = {
  Eu: "_eu",
  Us: "_us",
  All: "all",
  Ca: "ca",
  Eu: "eu",
  Gb: "gb",
  Mx: "mx",
  Us: "us",
  Za: "za",
} as const;
export type StoresFeaturedStoresListRegionEnum = (typeof StoresFeaturedStoresListRegionEnum)[keyof typeof StoresFeaturedStoresListRegionEnum];
/**
 * @export
 */
export const StoresListOrderingEnum = {
  Default: "default",
  StoreCaption: "store_caption",
  AdsCount: "ads_count",
  StoreCaption: "-store_caption",
  AdsCount: "-ads_count",
} as const;
export type StoresListOrderingEnum = (typeof StoresListOrderingEnum)[keyof typeof StoresListOrderingEnum];
/**
 * @export
 */
export const StoresListOrganizationTypeEnum = {
  Advocacy: "advocacy",
  Artist: "artist",
  Breeder: "breeder",
  Charity: "charity",
  Club: "club",
  ContentCreator: "content-creator",
  Courier: "courier",
  Education: "education",
  Expo: "expo",
  FeederBreeder: "feeder-breeder",
  HerpParty: "herp-party",
  ImporterExporter: "importer-exporter",
  Manufacturer: "manufacturer",
  Other: "other",
  PetStore: "pet-store",
  Publisher: "publisher",
  Rescue: "rescue",
  Retail: "retail",
  Seller: "seller",
  ShippingService: "shipping-service",
  Software: "software",
  TestingService: "testing-service",
  Vet: "vet",
  Zoo: "zoo",
} as const;
export type StoresListOrganizationTypeEnum = (typeof StoresListOrganizationTypeEnum)[keyof typeof StoresListOrganizationTypeEnum];
/**
 * @export
 */
export const StoresListPaymentMethodsEnum = {
  ApplePay: "apple_pay",
  Bitcoin: "bitcoin",
  Cash: "cash",
  Cashapp: "cashapp",
  CashiersCheck: "cashiers_check",
  Chime: "chime",
  CreditCard: "credit_card",
  DebitCard: "debit_card",
  Dogecoin: "dogecoin",
  Ethereum: "ethereum",
  GooglePay: "google_pay",
  MoneyOrder: "money_order",
  Moneygram: "moneygram",
  Other: "other",
  OtherCrypto: "other_crypto",
  PaypalFriendsFamily: "paypal_friends_family",
  PaypalGoodsServices: "paypal_goods_services",
  PersonalCheck: "personal_check",
  Venmo: "venmo",
  WalmartPay: "walmart_pay",
  WesternUnion: "western_union",
  WireTransfer: "wire_transfer",
  Zelle: "zelle",
} as const;
export type StoresListPaymentMethodsEnum = (typeof StoresListPaymentMethodsEnum)[keyof typeof StoresListPaymentMethodsEnum];
/**
 * @export
 */
export const StoresListPreyFoodEnum = {
  Chicken: "chicken",
  Cricket: "cricket",
  DryFeed: "dry-feed",
  Goat: "goat",
  GuineaPig: "guinea-pig",
  Lamb: "lamb",
  MealReplacement: "meal-replacement",
  Mealworm: "mealworm",
  Mouse: "mouse",
  Other: "other",
  Pig: "pig",
  Quail: "quail",
  Rabbit: "rabbit",
  Rat: "rat",
  Roach: "roach",
  SoftFurredRat: "soft-furred-rat",
  Unknown: "unknown",
  Vegetables: "vegetables",
} as const;
export type StoresListPreyFoodEnum = (typeof StoresListPreyFoodEnum)[keyof typeof StoresListPreyFoodEnum];
/**
 * @export
 */
export const StoresListPreyStatusEnum = {
  Frozen: "frozen",
  Live: "live",
  Prekilled: "prekilled",
  Unknown: "unknown",
} as const;
export type StoresListPreyStatusEnum = (typeof StoresListPreyStatusEnum)[keyof typeof StoresListPreyStatusEnum];
/**
 * @export
 */
export const StoresListRegionEnum = {
  Eu: "_eu",
  Us: "_us",
  All: "all",
  Ca: "ca",
  Eu: "eu",
  Gb: "gb",
  Mx: "mx",
  Us: "us",
  Za: "za",
} as const;
export type StoresListRegionEnum = (typeof StoresListRegionEnum)[keyof typeof StoresListRegionEnum];
/**
 * @export
 */
export const StoresOffspringGroupsListBirthDateEnum = {
  Today: "today",
  Yesterday: "yesterday",
  Week: "week",
  Month: "month",
  Year: "year",
} as const;
export type StoresOffspringGroupsListBirthDateEnum = (typeof StoresOffspringGroupsListBirthDateEnum)[keyof typeof StoresOffspringGroupsListBirthDateEnum];
/**
 * @export
 */
export const StoresOffspringGroupsListClutchEstimatedHatchDateEnum = {
  Today: "today",
  Yesterday: "yesterday",
  Week: "week",
  Month: "month",
  Year: "year",
} as const;
export type StoresOffspringGroupsListClutchEstimatedHatchDateEnum =
  (typeof StoresOffspringGroupsListClutchEstimatedHatchDateEnum)[keyof typeof StoresOffspringGroupsListClutchEstimatedHatchDateEnum];
/**
 * @export
 */
export const StoresOffspringGroupsListOrderingEnum = {
  Modified: "modified",
  Image: "image",
  GroupId: "group_id",
  Category: "category",
  Notes: "notes",
  Dams: "dams",
  Sires: "sires",
  LayDate: "lay_date",
  Eggs: "eggs",
  ViableEggs: "viable_eggs",
  NonViableEggs: "non_viable_eggs",
  BirthDate: "birth_date",
  Children: "children",
  Visibility: "visibility",
  Modified: "-modified",
  Image: "-image",
  GroupId: "-group_id",
  Category: "-category",
  Notes: "-notes",
  Dams: "-dams",
  Sires: "-sires",
  LayDate: "-lay_date",
  Eggs: "-eggs",
  ViableEggs: "-viable_eggs",
  NonViableEggs: "-non_viable_eggs",
  BirthDate: "-birth_date",
  Children: "-children",
  Visibility: "-visibility",
} as const;
export type StoresOffspringGroupsListOrderingEnum = (typeof StoresOffspringGroupsListOrderingEnum)[keyof typeof StoresOffspringGroupsListOrderingEnum];
/**
 * @export
 */
export const StoresOffspringGroupsListStatusEnum = {
  Current: "current",
  Gravid: "gravid",
  Incubation: "incubation",
  Ytd: "ytd",
  LastYear: "last_year",
} as const;
export type StoresOffspringGroupsListStatusEnum = (typeof StoresOffspringGroupsListStatusEnum)[keyof typeof StoresOffspringGroupsListStatusEnum];
/**
 * @export
 */
export const StoresOffspringGroupsListVisibilityEnum = {
  Public: "public",
  Unlisted: "unlisted",
  Private: "private",
} as const;
export type StoresOffspringGroupsListVisibilityEnum = (typeof StoresOffspringGroupsListVisibilityEnum)[keyof typeof StoresOffspringGroupsListVisibilityEnum];
